/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CometChat } from '@cometchat-pro/chat';
import { useWindowWidth } from '@react-hook/window-size';
import cx from 'classnames';
import moment from 'moment';
import pathToRegexp from 'path-to-regexp';
import { object } from 'prop-types';
import queryString from 'query-string';

import browserHistory from '../../browserHistory';
import { loginCometChatUser } from '../../components/CometChatWorkspace/utils';
import Footer from '../../components/Footer';
import IESupportModal from '../../components/IESupportModalComponent';
import LoadingScreen from '../../components/LoadingScreen';
import MobileAppBanner from '../../components/MobileAppBanner';
import DesktopNavigation from '../../components/navigation/DesktopNavigation';
import MobileNavigation from '../../components/navigation/MobileNavigation';
import { smallScreenWidth } from '../../constants/media-breakpoints';
import * as AuthService from '../../core/auth/authService';
import verifyConfirmationToken from '../../core/services/tokenService';
import setDeviceLoginToken from '../../helpers/set-device-login-token';
import LegacyHeader from '../../pages/common/layout/HeaderContainer';
import { useConfig } from '../../providers/ConfigProvider';
import { useGlobalBanner } from '../../providers/GlobalBannerProvider';
import { LayoutStateContext } from '../../providers/LayoutProvider';
import * as localStorageService from '../../services/localStorageService';
import {
  setCurrentUrlToRedirectAfterLogin,
  setUrlToRedirectAfterLogin,
} from '../../services/utilities';
import ExtraHeaderComponent from '../ExtraHeaderComponent';

import styles from './LegacyLayout.module.scss';

const LegacyLayout = ({ children }) => {
  const { IS_OWNER } = useConfig();
  const navigate = useNavigate();
  const { hasBanner: hasGlobalBanner } = useGlobalBanner();
  const screenWidth = useWindowWidth();
  const isSmallScreen = screenWidth <= smallScreenWidth;
  const [loading, setLoading] = useState(true);
  const [openedSidebar, setOpenedSidebar] = useState(!isSmallScreen);
  const [openIEModal, setOpenIEModal] = useState(false);
  const location = useLocation();
  const layoutStateContext = useContext(LayoutStateContext);
  const isRentalCountExperiment = true;
  const isPropertiesPath =
    location.pathname.indexOf('/owners/properties') !== -1;
  const noPaddings = isPropertiesPath && isRentalCountExperiment;

  const checkOwnerConfirmationToken = () => {
    const queryParameters = queryString.parse(location.search);
    if (queryParameters.confirmationToken && IS_OWNER) {
      const applicationId = location.pathname.substr(
        location.pathname.lastIndexOf('/') + 1,
      );

      verifyConfirmationToken(
        `verify?accountType=1&confirmationToken=${queryParameters.confirmationToken}&rrid=${applicationId}`,
      )
        .then((res) => {
          if (res.token || res.rr_token) {
            delete queryParameters.confirmationToken;
            AuthService.silentLogin(res.token || res.rr_token);
            setLoading(false);
            let params = '';
            Object.keys(queryParameters).forEach((k) => {
              params += `${k}=${queryParameters[k]}&`;
            });
            navigate(`${location.pathname}?${params}finishWarn=true`);
          } else {
            setLoading(false);
            return navigate('/');
          }
        })
        .catch(() => {
          return navigate('/auth/login', { replace: true });
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryParameters = queryString.parse(location.search);
    if (queryParameters.ref) {
      localStorageService.setItem('ref', queryParameters.ref);
    }

    window.scrollTo(0, 0);
    if (queryParameters.dlt) {
      setDeviceLoginToken(queryParameters.dlt);
      navigate(
        {
          ...location,
          search: `?${Object.keys(queryParameters)
            .filter((key) => key !== 'dlt')
            .map((key) => `${key}=${queryParameters[key]}`)
            .join('&')}`,
        },
        { replace: true },
      );
    }
    if (queryParameters.admin_token) {
      const messagingToken = queryParameters.messaging_token;
      if (messagingToken) {
        CometChat.logout().then(
          () => {
            console.log('Messaging Logout completed successfully');
          },
          (error) => {
            console.log('Messaging Logout failed with exception:', { error });
          },
        );
        // Makse sure to avoid any lag from CometChat side
        setTimeout(() => {
          loginCometChatUser(messagingToken);
        }, 1000);
      }
      if (queryParameters.redirectTo) {
        navigate(queryParameters.redirectTo, { replace: true });
      } else {
        navigate('/', { replace: true });
      }
      AuthService.adminLogin(queryParameters.admin_token);
      setLoading(false);
    } else if (
      /**
       * If you need to add a new route that will be logged in with a token
       * in the url, for example a CTA coming from an email, you need to add it
       * in this array and also in the if below in line 182 (inside the token if)
       */
      [
        '/esignature/sign/',
        '/esignature/welcome/',
        '/references',
        '/lead-questionnaire',
        '/upload-application-documents',
        '/upload-internalreview-documents',
        '/upload-stripe-verification-documents',
        '/renters/welcome',
        '/renthop/onboarding/process',
        '/renthop/onboarding/done',
        '/renters/messages',
        '/renters/insurance',
        '/renters/dashboard/insurance',
        '/renters/check-in',
        '/owners/messages',
        '/owners/payments/',
      ].some((route) => location.pathname.indexOf(route) !== -1) ||
      location.pathname.match(
        '/owners/(tenant|lead|applicant)-profile/(.*)/(tab_messages|tab_application)',
      ) ||
      ((location.pathname.match('/applications/(.*)/(.*)/(.*)') ||
        location.pathname.match('/renters/identity_verification')) &&
        queryParameters.token) ||
      location.pathname === '/'
    ) {
      const token = Object.keys(queryParameters)
        .filter((param) => param.indexOf('token') !== -1)
        .reduce((acc, next) => {
          if (queryParameters[next]) {
            return queryParameters[next];
          }
          return acc;
        }, '');

      if (token) {
        setLoading(false);
        if (
          location.pathname.indexOf('/esignature/welcome') !== -1 ||
          location.pathname.indexOf('/upload-application-documents') !== -1 ||
          location.pathname.indexOf('/upload-stripe-verification-documents') !==
            -1 ||
          location.pathname.indexOf('/upload-internalreview-documents') !==
            -1 ||
          location.pathname.indexOf('/renters/welcome') !== -1 ||
          location.pathname.indexOf('/renthop/process') !== -1 ||
          location.pathname.indexOf('/renters/messages') !== -1 ||
          location.pathname.indexOf('/renters/insurance') !== -1 ||
          location.pathname.indexOf('/renters/dashboard/insurance') !== -1 ||
          location.pathname.indexOf('/renters/check-in') !== -1 ||
          location.pathname.indexOf('/owners/messages') !== -1 ||
          location.pathname.match(
            '/owners/(tenant|lead|applicant)-profile/(.*)/(tab_messages|tab_application)',
          ) ||
          location.pathname === '/' ||
          location.pathname.includes('/owners/payments/') ||
          location.pathname.indexOf('/applications') !== -1 ||
          location.pathname.match('/renters/identity_verification')
        ) {
          AuthService.login(token);
        }
      } else if (
        !AuthService.isLoggedIn() &&
        !AuthService.getAlternateToken()
      ) {
        // adding this as a quick fix for marketing team, since they want to send an email with
        // direct link and after user is logged in, they should be redirected to the page
        // @TODO - figure out how to do this in a better way
        if (location.pathname.indexOf('/owners/payments/accounting') !== -1) {
          setCurrentUrlToRedirectAfterLogin();
        } else {
          setUrlToRedirectAfterLogin(''); //flush on load //@TODO - change this scheme to something else
        }
        setLoading(false);
        browserHistory.replace('/auth/login');
      } else {
        setLoading(false);
      }
    } else if (location.pathname.indexOf('/applications') !== -1) {
      // we do not kick out the RENTER if the token is expired or not valid we do it later
      if (IS_OWNER) {
        checkOwnerConfirmationToken();
      }
      setLoading(false);
    } else if (location.pathname.indexOf('/applications') === -1) {
      AuthService.tryLoginFromLocalStorage();
      setLoading(false);
    } else {
      setLoading(false);
    }

    //Show IE Modal only in that browser and if the expiration date is reached
    const lsDate = localStorageService.getItem('ie_support_modal_expiration');
    const expirationDate =
      (lsDate && moment().format(lsDate, 'YYYY-MM-DD')) || null;
    if (
      window.navigator.userAgent.indexOf('Trident/') !== -1 &&
      (!expirationDate || moment().isAfter(expirationDate))
    ) {
      setOpenIEModal(true);
      localStorage.setItem(
        'ie_support_modal_expiration',
        moment().add(30, 'days'),
      );
    }
  }, []);

  useEffect(() => {
    const { pathname } = location;

    const forbiddenRoutesToScrollTop = [
      'properties/manage',
      'applicant-profile',
      'tenant-profile',
      'home_guide',
      'dashboard/home-guide',
    ];

    if (
      forbiddenRoutesToScrollTop.every(
        (route) => pathname.indexOf(route) === -1,
      )
    ) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const toggleSideBar = (e) => {
    if (e) {
      e.preventDefault();
    }
    setOpenedSidebar(!openedSidebar);
  };

  const hasMobileBottomNavBar = () =>
    (IS_OWNER &&
      AuthService.isLoggedIn() &&
      location.pathname.indexOf('/owners/mobile/stripe-token') === -1 &&
      location.pathname.indexOf('/owners/mobile/add-stripe-info') === -1 &&
      location.pathname.indexOf('/owners/plaid-authentication') === -1 &&
      location.pathname.indexOf('owners') !== -1) ||
    (!IS_OWNER &&
      location.pathname.indexOf('/renters/plaid-authentication') === -1 &&
      AuthService.isLoggedIn() &&
      location.pathname.indexOf('/renters/mobile/add-card') === -1 &&
      location.pathname.indexOf('/renters/mobile/verify-identity') === -1 &&
      location.pathname.indexOf('/renters/mobile/esignature/sign') === -1 &&
      location.pathname.indexOf('renters') !== -1);

  const confirmEmail = location?.pathname === '/auth/welcome';

  const isMarketingLayout =
    pathToRegexp([
      '/onboarding/process',
      '/onboarding/subscription/:type',
      '/onboarding/personalize',
      '/onboarding/add-property',
      '/onboarding/marketing-introduction',
      '/onboarding/property',
      '/onboarding/property/setup(.*)',
      '/onboarding/invite-renter',
      '/onboarding/invite-success',
      '/onboarding/invite-success',
      '/onboarding/income-insights-promo',
      '/auth/welcome',
      '/auth/notrecognized',
      '/onboarding/mailing-address',
      '/auth/signup',
      '/auth/signup/personalize',
      '/auth/signup/final',
      '/auth/signup/final/:subscription',
      '/auth/forum/signup',
      '/auth/forum/login',
      '/auth/academy/signup',
      '/auth/appsumo',
      '/auth/tenant/setpassword',
      '/auth/setpassword',
      '/auth/academy/login',
      '/onboarding/academy-vs-product',
      '/owners/properties/manage',
      '/upload-internalreview-documents(.*)',
      '/upload-stripe-verification-documents(.*)',
      '/rent-estimate-report(.*)',
      '/renthop/onboarding(.*)',
      '/onboarding/add-lease',
      '/onboarding/link-your-application',
      '/onboarding/screening-plan',
      '/extend-trial/(.*)',
      '/owners/payments/onboarding(.*)',
      '/renters/insurance/setup(.*)',
      '/onboarding/choose-process(.*)',
      '/renters/onboarding(.*)',
      '/email-preview(.*)',
      '/renters/showing(.*)',
      '/auth/signup/personalize/:plan',
    ]).test(location.pathname) ||
    pathToRegexp('/auth/signup/:step').test(location.pathname) ||
    (pathToRegexp('/auth/resetpassword/:type').test(location.pathname) &&
      IS_OWNER) ||
    (pathToRegexp('/auth/:ssoService/resetpassword/:type').test(
      location.pathname,
    ) &&
      IS_OWNER) ||
    (pathToRegexp('/auth/login').test(location.pathname) && IS_OWNER);

  const inAppLayouts = pathToRegexp([
    '/owners/properties/manage/:listingGlobalId/(.*)',
    '/renters/dashboard(.*)',
    '/renters/messages',
    '/renters/welcome',
    '/owners/lead-profile/(.*)',
    '/owners/leases/view/(.*)',
    '/owners/applicant-profile(.*)',
    '/owners/tenant-profile(.*)',
    '/owners/subscription',
    '/owners/forms-subscription',
    '/owners/extend-trial',
    '/listingsearch',
    '/listingsearch/results(.*)',
    '/turbolist/thankyou',
    '/general-prescreener/(.*)',
    '/lead-questionnaire/(.*)',
    '/owners/payments(.*)',
    '/owners/showings/(.*)',
    '/owners/leases/:leaseId/condition_reports/:conditionReportId/fill-out',
  ]).test(location.pathname);

  const hasSideBar =
    ['/renter', '/owners'].some(
      (route) =>
        location.pathname.indexOf(route) !== -1 &&
        location.pathname.indexOf('/renters/esignature') === -1 &&
        location.pathname.indexOf('/renters/listings') === -1 &&
        location.pathname.indexOf('/renters/welcome') === -1 &&
        location.pathname.indexOf('/renters/mobile/add-card') === -1 &&
        location.pathname.indexOf('/renters/mobile/verify-identity') === -1 &&
        location.pathname.indexOf('/owners/mobile/stripe-token') === -1 &&
        location.pathname.indexOf('/owners/mobile/add-stripe-info') === -1 &&
        location.pathname.indexOf('/renters/plaid-authentication') === -1 &&
        location.pathname.indexOf('/owners/plaid-authentication') === -1,
    ) || location.pathname === '/';

  const printPropertyPage = location.pathname.indexOf('property-print') !== -1;

  const withWhiteBackground = [
    '/properties/',
    '/renters/messages',
    '/renters/welcome',
    '/rent-estimate-report(.*)',
    '/p/',
  ];
  if (isMobile) {
    withWhiteBackground.push('/renters/identity_verification');
  }
  const hasWhiteBackground = withWhiteBackground.some(
    (route) => location.pathname.indexOf(route) === 0,
  );

  const hasMobileNavigation = hasMobileBottomNavBar();

  const receiptPage = pathToRegexp('/receipt/:id/:type').test(
    location.pathname,
  );

  const messagePage =
    ['/renters/messages', '/owners/messages'].includes(location.pathname) ||
    pathToRegexp('/owners/messages/:receiverUID').test(location.pathname) ||
    pathToRegexp('/renters/messages/:receiverUID').test(location.pathname);
  const fullWidthLayout = [
    '/owners/properties/setup',
    '/renters/condition-report',
    '/renters/mobile/add-card',
    '/renters/mobile/verify-identity',
  ].some((route) => location.pathname.indexOf(route) !== -1);

  const showFooter = !['/renters', '/owners', '/onboarding', '/signup'].some(
    (route) => location.pathname.indexOf(route) !== -1,
  );

  const { successNotif } = queryString.parse(location.search);

  if (printPropertyPage) {
    return (
      <div id="outer-container" className={styles.container}>
        <div
          id="page-wrap"
          className={cx(styles.pageWrap, {
            [styles.hasWhiteBackground]: hasWhiteBackground,
          })}
        >
          <div
            className={cx(styles.innerContainer, {
              [styles.paddingForNotification]: successNotif,
            })}
          >
            {!loading && <>{children}</>}
            {loading && <LoadingScreen loading={loading} />}
          </div>
        </div>
      </div>
    );
  }

  if (receiptPage) {
    return children;
  }

  if (isMarketingLayout) {
    return <div>{children}</div>;
  }

  if (inAppLayouts) {
    return (
      <div className={styles.container}>
        <input
          type="checkbox"
          id="toggle-sidebar"
          checked={openedSidebar}
          onChange={toggleSideBar}
          className={styles.toggleSidebar}
        />
        {!loading && hasSideBar && <DesktopNavigation />}
        <div
          className={cx({
            [styles.sidebarPadding]: hasSideBar,
            [styles.mobileNavPadding]: hasMobileNavigation,
            [styles.noPaddings]: noPaddings,
          })}
        >
          <MobileAppBanner />
          {!loading ? (
            <>{children}</>
          ) : (
            <LoadingScreen loading className={styles.loadingScreen} />
          )}
          {hasMobileNavigation && <MobileNavigation />}
        </div>
      </div>
    );
  }

  return (
    <div
      id="outer-container"
      className={cx(styles.outerContainer, styles.newOuterContainerStyles, {
        [styles.confirmEmailBackground]: confirmEmail,
        [styles.hasMobileNavigation]: hasMobileNavigation,
        [styles.messagePage]: messagePage,
        [styles.fullWidthLayout]: fullWidthLayout,
        [styles.mobileNavPadding]: hasMobileNavigation,
      })}
    >
      <IESupportModal
        open={openIEModal}
        onClose={() => {
          setOpenIEModal(false);
        }}
      />
      <input
        type="checkbox"
        id="toggle-sidebar"
        checked={openedSidebar}
        onChange={toggleSideBar}
        className={styles.toggleSidebar}
      />
      {!loading && hasSideBar && <DesktopNavigation />}
      <div
        id="page-wrap"
        className={cx(styles.pageWrap, {
          [styles.hasWhiteBackground]: hasWhiteBackground,
          [styles.hasSideBar]: hasSideBar,
          [styles.messagePage]: messagePage,
          [styles.fullWidthLayout]: fullWidthLayout,
          [styles.noPaddings]: noPaddings,
        })}
      >
        <LegacyHeader
          pathname={location.pathname}
          hasWhiteBackground={hasWhiteBackground}
        >
          <ExtraHeaderComponent />
        </LegacyHeader>
        <div
          className={cx(styles.innerContainer, {
            [styles.hasTabsLayout]: layoutStateContext?.settings?.hasTabsLayout,
            [styles.innerContainerFullWidth]: fullWidthLayout,
            [styles.messagesInnerContainer]: messagePage,
            [styles.hasGlobalBanner]: hasGlobalBanner,
          })}
        >
          <MobileAppBanner />
          {!loading && <>{children}</>}
          {loading && (
            <LoadingScreen loading className={styles.loadingScreen} />
          )}
        </div>
        {showFooter && <Footer className={styles.footer} />}
        {hasMobileNavigation && <MobileNavigation />}
      </div>
    </div>
  );
};

LegacyLayout.propTypes = {
  children: object,
};

export default LegacyLayout;
